<template>
  <div class="manage-property-exclusive">
    <div class="container fluid">
      <div class="card p-2 sm-p-3 md-p-4">
        <div class="d-flex align-items-center mb-6">
          <fd-button class="circle mr-2" @click="toProperty">
            <i class="fas fa-chevron-left"></i>
          </fd-button>
          <h1 class="">Manage Exclusive</h1>
        </div>
        <property-summary
          :property="property"
          :isLoading.sync="isPropertyLoading"
          class="md-col-6 mb-5"
          owner
        ></property-summary>

        <!-- ================== Approved Exclusive Status ================== -->
        <div v-if="hasApprovedExclusive">
          <fd-form-section
            title="Current Exclusive Status"
            class="row col-12 md-col-6"
          >
            <exclusive-submission-status
              :status="approvedExclusiveInfo.status"
              class="mb-2"
            ></exclusive-submission-status>
            <!-- Info -->
            <div
              class="col-12 row justify-content-between bordered-card p-2 mb-2"
            >
              <display-data
                label="Expiry Date"
                :content="$dayjs(approvedIsoExpiryDate).format('DD-MM-YYYY')"
              >
              </display-data>
              <chip
                v-if="
                  $dayjs(approvedIsoExpiryDate).diff($dayjs(), 'day', true) > 0
                "
                :class="[
                  getExpiryHintStyle(approvedIsoExpiryDate),
                  'fg-white ml-2'
                ]"
              >
                Expires in
                <span class="font-bold">
                  {{
                    getDateDiff(approvedIsoExpiryDate, {
                      full: true,
                      space: true,
                      absolute: true
                    })
                  }}
                </span>
              </chip>
              <chip v-else class="bg-danger fg-white">Expired</chip>
            </div>
            <download-files
              label="Supporting Documents"
              class="col-12 p-2"
              :downloadItem="approvedExclusiveInfo.supportedDocuments"
              :filenamePrefix="`exclusive_support_doc`"
            >
            </download-files>
          </fd-form-section>
          <hr class="my-5" />
        </div>

        <!-- ================== Current Exclusive Status ================== -->
        <div v-if="isCurrentExclusiveShown">
          <fd-form-section
            title="Recent Exclusive Application"
            class="row col-12 md-col-6"
          >
            <exclusive-submission-status
              :status="exclusiveInfo.status"
              class="mb-2"
            ></exclusive-submission-status>
            <!-- Info -->
            <div
              class="col-12 row justify-content-between bordered-card p-2 mb-2"
            >
              <display-data
                label="Expiry Date"
                :content="$dayjs(latestIsoExpiryDate).format('DD-MM-YYYY')"
              >
              </display-data>
              <chip
                v-if="
                  $dayjs(latestIsoExpiryDate).diff($dayjs(), 'day', true) > 0
                "
                :class="[
                  getExpiryHintStyle(latestIsoExpiryDate),
                  'fg-white ml-2'
                ]"
              >
                Expires in
                <span class="font-bold">
                  {{
                    getDateDiff(latestIsoExpiryDate, {
                      full: true,
                      space: true,
                      absolute: true
                    })
                  }}
                </span>
              </chip>
              <chip v-else class="bg-danger fg-white">Expired</chip>
            </div>
            <download-files
              label="Supporting Documents"
              class="col-12 p-2"
              :downloadItem="exclusiveInfo.supportedDocuments"
              :filenamePrefix="`exclusive_support_doc`"
            >
            </download-files>
          </fd-form-section>
          <hr class="my-5" />
        </div>
        <banner
          v-else-if="!hasApprovedExclusive"
          class="bg-grey-100 py-2 mb-5"
          :dismissable="false"
        >
          This property has yet to apply exclusive status.
        </banner>

        <update-exclusive
          v-if="_.get(exclusiveInfo, 'status.id') != STATUS.PENDING"
          :type="typeOfSubmission"
          @submit="submitExclusive"
        ></update-exclusive>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateExclusive from "@/components/GlobalComponents/Shared/Property/UpdateExclusive";
import { getDateDiff } from "@/utils/datetime";
import { exclusiveListing as exclusiveAPI } from "@/api";
import { exclusiveSubmissionStatus as STATUS } from "@/enums";

export default {
  components: {
    UpdateExclusive,
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    ExclusiveSubmissionStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/Property/ExclusiveSubmissionStatus"
      ),
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      getDateDiff,
      STATUS,

      isPropertyLoading: false,
      isExclusiveInfoLoading: false,
      hasExistingExclusive: false,
      exclusiveInfo: {},

      isApprovedExclusiveLoading: false,
      hasApprovedExclusive: false,
      approvedExclusiveInfo: {},
      property: {}
    };
  },
  computed: {
    typeOfSubmission() {
      return this.hasApprovedExclusive ? "extension" : "application";
    },
    approvedIsoExpiryDate() {
      return this.$dayjs(
        this.approvedExclusiveInfo.exclusiveUntilDate,
        "YYYY-MM-DD"
      ).toISOString();
    },
    latestIsoExpiryDate() {
      return this.$dayjs(
        this.exclusiveInfo.exclusiveUntilDate,
        "YYYY-MM-DD"
      ).toISOString();
    },
    isCurrentExclusiveShown() {
      return this.hasExistingExclusive && this.isLatestExclusiveApproved;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getProperty(), this.getExclusiveInfo()]);
    },
    toProperty() {
      this.$router.push({
        name: "ManagePropertyDetail",
        params: {
          id: this.$route.params.id
        }
      });
    },
    isLatestExclusiveApproved() {
      return (
        (this.approvedExclusiveInfo?.createdAt ?? 0) <
        (this.exclusiveInfo?.createdAt ?? 1)
      );
    },
    getExpiryHintStyle(date) {
      let diff = this.$dayjs(date).diff(this.$dayjs(), "day", true);

      if (diff < 7) return "bg-danger";
      else if (diff < 14) return "bg-warn";
      else return "bg-main";
    },
    async getProperty() {
      try {
        this.isPropertyLoading = true;
        let data = await this.$store.dispatch("manageProperty/getMyProperty", {
          id: this.$route.params.id
        });
        this.property = this._.cloneDeep(data);
        this.isPropertyLoading = false;
      } catch (error) {
        this.isPropertyLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch property details. Please try again later."
        });
      }
    },
    async getExclusiveInfo() {
      try {
        this.getLatestExclusiveInfo();
        this.getApprovedExclusiveInfo();
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch exclusive info. Please try again later."
        });
      }
    },
    async getLatestExclusiveInfo() {
      try {
        this.isExclusiveInfoLoading = true;
        let data = await exclusiveAPI.getExclusiveInfo(this.$route.params.id);
        if (data.isApplied) {
          this.exclusiveInfo = this._.cloneDeep(data);
        }
        this.hasExistingExclusive = data.isApplied;

        this.isExclusiveInfoLoading = false;
      } catch (error) {
        this.isExclusiveInfoLoading = false;
      }
    },
    async getApprovedExclusiveInfo() {
      try {
        this.isApprovedExclusiveLoading = true;
        let data = await exclusiveAPI.getApprovedExclusiveInfo(
          this.$route.params.id
        );
        this.hasApprovedExclusive = data.isApplied;

        if (data.isApplied) {
          this.approvedExclusiveInfo = this._.cloneDeep(data);
        }

        this.isApprovedExclusiveLoading = false;
      } catch (error) {
        this.isApprovedExclusiveLoading = false;
      }
    },
    async submitExclusive(form, clearForm) {
      if (this.hasApprovedExclusive) {
        await this.extendExclusive(form, clearForm);
      } else {
        await this.applyForExclusive(form, clearForm);
      }
    },
    async applyForExclusive(form, clearForm) {
      let confirm = await this.$confirm({
        title: "Apply Exclusive",
        message: `Confirm submit you application for exclusive? You cannnot make a change to your submission when pending administrator's review`,
        confirmText: "Confirm"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await exclusiveAPI.applyExclusive(this.$route.params.id, form);
          clearForm();
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Exclusive application has been submitted."
          });
          await this.getExclusiveInfo();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Failed to submit exclusive application. Please try again later."
          });
        }
      }
    },
    async extendExclusive(form, clearForm) {
      let confirm = await this.$confirm({
        title: "Extend Exclusive Status",
        message: `Confirm submit you extension for exclusive?`,
        confirmText: "Submit"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await exclusiveAPI.extendExclusive(this.$route.params.id, form);
          clearForm();
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Exclusive extension has been submitted."
          });
          await this.getExclusiveInfo();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Failed to submit exclusive extension. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
