const acceptedFileType =
    "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/pdf,image/*";
const allowedFormat = [
    "pdf",
    "doc",
    "docx",
    "odt",
    "ods",
    "jpg",
    "jpeg",
    "png"
];

export { acceptedFileType, allowedFormat };
