<template>
  <div class="update-exclusive">
    <fd-form @submit="$emit('submit', form, clearForm)">
      <!-- New Exclusive Submission -->
      <div class="row">
        <h4 class="col-12 mb-2">
          <i class="far fa-file-alt mr-1"></i> Exclusive {{ toTitleCase(type) }}
        </h4>
        <fd-date-picker
          v-model="form.exclusiveUntilDate"
          class="col-12 sm-col-6 md-col-4 lg-col-3 mb-2"
          label="Expiry Date"
          name="expiryDate"
          type="text"
          :min="$dayjs().toISOString()"
          :validators="[validator.required]"
        >
        </fd-date-picker>
        <div class="col-12 mb-2">
          <file-uploader
            v-model="form.supportedDocuments"
            label="Supporting Documents"
            :maxFile="20"
            :maxSize="10"
            :accept="acceptedFileType"
            :allowed="allowedFormat"
            multiple
            required
            :isLoading.sync="isUploading"
          ></file-uploader>
        </div>
      </div>

      <div class="d-flex justify-content-end p-2">
        <fd-button
          type="submit"
          class="main medium ml-2"
          :disabled="isUploading"
          >Submit {{ toTitleCase(type) }}</fd-button
        >
      </div>
    </fd-form>
  </div>
</template>

<script>
import {
  acceptedFileType,
  allowedFormat
} from "@/components/GlobalComponents/FormComponents/Validator/fileType";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { toTitleCase } from "@/utils/string";
export default {
  components: {
    FileUploader: () => import("@/components/GlobalComponents/FileUploader")
  },
  mixins: [],
  props: {
    property: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      validator: (val) => ["extension", "application"].includes(val)
    }
  },
  data: function () {
    return {
      isUploading: false,

      form: {
        exclusiveUntilDate: "",
        supportedDocuments: []
      },

      validator: {
        required
      },
      acceptedFileType,
      allowedFormat,
      toTitleCase
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    clearForm() {
      this.form = {
        exclusiveUntilDate: "",
        supportedDocuments: []
      };
    }
  }
};
</script>

<style lang="scss"></style>
