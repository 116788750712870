var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-property-exclusive"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"card p-2 sm-p-3 md-p-4"},[_c('div',{staticClass:"d-flex align-items-center mb-6"},[_c('fd-button',{staticClass:"circle mr-2",on:{"click":_vm.toProperty}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('h1',{},[_vm._v("Manage Exclusive")])],1),_c('property-summary',{staticClass:"md-col-6 mb-5",attrs:{"property":_vm.property,"isLoading":_vm.isPropertyLoading,"owner":""},on:{"update:isLoading":function($event){_vm.isPropertyLoading=$event},"update:is-loading":function($event){_vm.isPropertyLoading=$event}}}),(_vm.hasApprovedExclusive)?_c('div',[_c('fd-form-section',{staticClass:"row col-12 md-col-6",attrs:{"title":"Current Exclusive Status"}},[_c('exclusive-submission-status',{staticClass:"mb-2",attrs:{"status":_vm.approvedExclusiveInfo.status}}),_c('div',{staticClass:"col-12 row justify-content-between bordered-card p-2 mb-2"},[_c('display-data',{attrs:{"label":"Expiry Date","content":_vm.$dayjs(_vm.approvedIsoExpiryDate).format('DD-MM-YYYY')}}),(
                _vm.$dayjs(_vm.approvedIsoExpiryDate).diff(_vm.$dayjs(), 'day', true) > 0
              )?_c('chip',{class:[
                _vm.getExpiryHintStyle(_vm.approvedIsoExpiryDate),
                'fg-white ml-2'
              ]},[_vm._v(" Expires in "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.getDateDiff(_vm.approvedIsoExpiryDate, { full: true, space: true, absolute: true }))+" ")])]):_c('chip',{staticClass:"bg-danger fg-white"},[_vm._v("Expired")])],1),_c('download-files',{staticClass:"col-12 p-2",attrs:{"label":"Supporting Documents","downloadItem":_vm.approvedExclusiveInfo.supportedDocuments,"filenamePrefix":"exclusive_support_doc"}})],1),_c('hr',{staticClass:"my-5"})],1):_vm._e(),(_vm.isCurrentExclusiveShown)?_c('div',[_c('fd-form-section',{staticClass:"row col-12 md-col-6",attrs:{"title":"Recent Exclusive Application"}},[_c('exclusive-submission-status',{staticClass:"mb-2",attrs:{"status":_vm.exclusiveInfo.status}}),_c('div',{staticClass:"col-12 row justify-content-between bordered-card p-2 mb-2"},[_c('display-data',{attrs:{"label":"Expiry Date","content":_vm.$dayjs(_vm.latestIsoExpiryDate).format('DD-MM-YYYY')}}),(
                _vm.$dayjs(_vm.latestIsoExpiryDate).diff(_vm.$dayjs(), 'day', true) > 0
              )?_c('chip',{class:[
                _vm.getExpiryHintStyle(_vm.latestIsoExpiryDate),
                'fg-white ml-2'
              ]},[_vm._v(" Expires in "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.getDateDiff(_vm.latestIsoExpiryDate, { full: true, space: true, absolute: true }))+" ")])]):_c('chip',{staticClass:"bg-danger fg-white"},[_vm._v("Expired")])],1),_c('download-files',{staticClass:"col-12 p-2",attrs:{"label":"Supporting Documents","downloadItem":_vm.exclusiveInfo.supportedDocuments,"filenamePrefix":"exclusive_support_doc"}})],1),_c('hr',{staticClass:"my-5"})],1):(!_vm.hasApprovedExclusive)?_c('banner',{staticClass:"bg-grey-100 py-2 mb-5",attrs:{"dismissable":false}},[_vm._v(" This property has yet to apply exclusive status. ")]):_vm._e(),(_vm._.get(_vm.exclusiveInfo, 'status.id') != _vm.STATUS.PENDING)?_c('update-exclusive',{attrs:{"type":_vm.typeOfSubmission},on:{"submit":_vm.submitExclusive}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }